import React, { useEffect } from 'react';
import './App.css';
import emailjs from 'emailjs-com';

const starPositions = [
  { top: '12%', left: '37%' }, { top: '19%', left: '56%' },
  { top: '33%', left: '43%' }, { top: '40%', left: '65%' },
  { top: '52%', left: '10%' }, { top: '61%', left: '23%' },
  { top: '68%', left: '57%' }, { top: '75%', left: '35%' }, { top: '83%', left: '18%' },
  { top: '90%', left: '48%' }, { top: '3%', left: '80%' }, { top: '9%', left: '62%' },
  { top: '17%', left: '29%' }, { top: '22%', left: '50%' }, { top: '30%', left: '15%' },
  { top: '36%', left: '74%' }, { top: '42%', left: '9%' },
  { top: '62%', left: '46%' }, { top: '67%', left: '26%' },
  { top: '74%', left: '11%' }, { top: '82%', left: '58%' }, { top: '88%', left: '42%' },
  { top: '95%', left: '21%' }, { top: '6%', left: '67%' }, { top: '13%', left: '39%' },
  { top: '20%', left: '72%' }, { top: '27%', left: '48%' }, { top: '34%', left: '18%' },
  { top: '41%', left: '90%' },
  { top: '61%', left: '40%' }, { top: '68%', left: '22%' }, 
  { top: '81%', left: '56%' }, { top: '87%', left: '30%' }, { top: '94%', left: '17%' },
  { top: '5%', left: '50%' }, { top: '12%', left: '83%' }, { top: '18%', left: '37%' },
  { top: '25%', left: '61%' }, { top: '33%', left: '44%' }, { top: '39%', left: '11%' },
  { top: '61%', left: '68%' },
  { top: '68%', left: '15%' }, { top: '74%', left: '91%' }, { top: '81%', left: '33%' },
  { top: '87%', left: '60%' }, { top: '93%', left: '25%' }, 
  { top: '14%', left: '47%' }, { top: '22%', left: '69%' }, { top: '28%', left: '12%' },
  { top: '35%', left: '85%' }, { top: '42%', left: '31%' },
  { top: '63%', left: '73%' }, { top: '70%', left: '45%' },
  { top: '77%', left: '8%' }, { top: '83%', left: '67%' }, { top: '89%', left: '36%' },
  { top: '96%', left: '52%' },
  { top: '19%', left: '39%' }, { top: '33%', left: '17%' },
  { top: '40%', left: '64%' }, { top: '46%', left: '10%' },
  { top: '67%', left: '55%' }, { top: '73%', left: '92%' },
  { top: '80%', left: '41%' }, { top: '86%', left: '70%' }, { top: '93%', left: '28%' },
  { top: '15%', left: '5%' }, { top: '50%', left: '2%' }, { top: '60%', left: '7%' },
  { top: '90%', left: '10%' }, { top: '93%', left: '3%' }, { top: '105%', left: '10%' },
  { top: '108%', left: '20%' }, { top: '104%', left: '28%' },
  { top: '90%', left: '84%' }, { top: '90%', left: '94%' }, { top: '104%', left: '76%' },
  { top: '10%', left: '90%' }, { top: '20%', left: '94%' }, { top: '50%', left: '98%' },
  { top: '102%', left: '90%' }
];

const gStarPositions = [
  { top: '7%', left: '20%' }, { top: '5%', left: '15%' }, { top: '10%', left: '11%' },
  { top: '17%', left: '11%' }, { top: '23%', left: '14%' }, { top: '23%', left: '19.5%' },
  { top: '16%', left: '19.5%' }, { top: '16%', left: '16.5%' }
];
const gStarRefs = gStarPositions.map(() => React.createRef());

const aStarPositions = [
  { top: '25%', left: '75.5%' }, { top: '16%', left: '76.5%' }, { top: '6%', left: '78%' },
  { top: '16%', left: '80.5%' }, { top: '16%', left: '76.5%' }, { top: '25%', left: '82%' }
];
const aStarRefs = aStarPositions.map(() => React.createRef());

const cStarPositions = [
  { top: '60%', left: '85%' }, { top: '63%', left: '81%' }, { top: '70%', left: '79%' },
  { top: '77%', left: '80.5%' }, { top: '78.5%', left: '84%' }, { top: '78%', left: '87%' }
];
const cStarRefs = cStarPositions.map(() => React.createRef());

let isHoveringGAMES = false;
let isHoveringABOUT = false;
let isHoveringCONTACT = false;
let isTouchDevice = false;

const drawLines = (positions, refs, index = 0) => {
  if ((positions === gStarPositions && !isHoveringGAMES) || 
      (positions === aStarPositions && !isHoveringABOUT) ||
      (positions === cStarPositions && !isHoveringCONTACT) ||
      index >= refs.length - 1) return;

  const line = document.createElement('div');
  line.className = 'line';
  line.style.position = 'absolute';
  line.style.backgroundColor = '#fff';
  line.style.height = '2px';
  line.style.transition = 'width 0.3s ease'; // Match the transition duration

  let startStar, endStar;
  if (positions === aStarPositions && index === 4) {
    // Special case to handle the fourth to sixth connection for letter "A"
    startStar = refs[3].current.getBoundingClientRect();
    endStar = refs[5].current.getBoundingClientRect();
  } else {
    startStar = refs[index].current.getBoundingClientRect();
    endStar = refs[index + 1].current.getBoundingClientRect();
  }

  const x1 = startStar.left + startStar.width / 2 + window.scrollX;
  const y1 = startStar.top + startStar.height / 2 + window.scrollY;
  const x2 = endStar.left + endStar.width / 2 + window.scrollX;
  const y2 = endStar.top + endStar.height / 2 + window.scrollY;

  line.style.left = `${x1}px`;
  line.style.top = `${y1}px`;
  line.style.width = '0px'; // Start with width 0
  line.style.transformOrigin = '0 0';
  line.style.transform = `rotate(${Math.atan2(y2 - y1, x2 - x1)}rad)`;

  document.body.appendChild(line);

  // Trigger the animation by setting the final width
  requestAnimationFrame(() => {
    requestAnimationFrame(() => { // Ensure two animation frames for better timing
      line.style.width = `${Math.hypot(x2 - x1, y2 - y1)}px`;
    });
  });

  // Wait for the current animation to complete before drawing the next line
  setTimeout(() => {
    if (positions === aStarPositions && index === 3) {
      // Draw from the fourth to the fifth, then manually trigger fourth to sixth
      drawLines(positions, refs, 4);
      drawLines(positions, refs, 3); // Trigger fourth to sixth after drawing fourth to fifth
    } else if (!(positions === aStarPositions && index === 4)) {
      // Skip the fifth to sixth connection
      drawLines(positions, refs, index + 1);
    }
  }, 200); // Duration should match the CSS transition duration exactly
};


const handleMouseEnterGAMES = () => {
  if (!isTouchDevice) {
    isHoveringGAMES = true;
    drawLines(gStarPositions, gStarRefs);
  }
};

const handleMouseLeaveGAMES = () => {
  if (!isTouchDevice) {
    isHoveringGAMES = false;
    document.querySelectorAll('.line').forEach(line => line.remove());
  }
};

const handleMouseEnterABOUT = () => {
  if (!isTouchDevice) {
    isHoveringABOUT = true;
    drawLines(aStarPositions, aStarRefs);
  }
};

const handleMouseLeaveABOUT = () => {
  if (!isTouchDevice) {
    isHoveringABOUT = false;
    document.querySelectorAll('.line').forEach(line => line.remove());
  }
};

const handleMouseEnterCONTACT = () => {
  if (!isTouchDevice) {
    isHoveringCONTACT = true;
    drawLines(cStarPositions, cStarRefs);
  }
};

const handleMouseLeaveCONTACT = () => {
  if (!isTouchDevice) {
    isHoveringCONTACT = false;
    document.querySelectorAll('.line').forEach(line => line.remove());
  }
};



const handleSubmit = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  const data = Object.fromEntries(formData);

  const button = e.target.querySelector('button');
  const batteryFill = button.querySelector('.battery-fill');
  let width = 0;

  // Trigger astronaut animation immediately
  animateAstronaut();

  const fillInterval = setInterval(() => {
    width += 20;
    batteryFill.style.width = `${width}%`;

    if (width >= 100) {
      clearInterval(fillInterval);
      
      emailjs.send(
        'service_z185vpa',
        'template_ytqzt6p',
        {
          from_name: data.name,
          from_email: data.email,
          subject: data.subject,
          message: data.message,
        },
        '_sqOl9WiJsl39BMnZ'
      )
      .then((response) => {
        console.log('Email sent successfully:', response);
        alert('Thank you for your message! We will get back to you soon.');
        e.target.reset();
        batteryFill.style.width = '0';
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('There was an error sending your message. Please try again.');
        batteryFill.style.width = '0';
      });
    }
  }, 100);
};

function animateAstronaut() {
  const astronaut = document.querySelector('.astronaut');
  astronaut.style.transition = 'transform 5s linear';
  setTimeout(() => {
    astronaut.style.transform = 'translateX(130vw) rotate(360deg)';
  }, 3000); // 2000 milliseconds = 2 seconds
}

function App() {
  useEffect(() => {
    // Check if the device supports touch events
    isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const viewportWidth = window.innerWidth;

      const scrollRatioAstronaut = (scrollPosition / maxScroll) * 2.6;
      
      const moveSpaceship = (selector, speed) => {
        const spaceship = document.querySelector(selector);
        const spaceshipPosition = Math.min(scrollRatioAstronaut * viewportWidth * speed, viewportWidth);
        spaceship.style.transform = `translateX(${spaceshipPosition}px)`;
        
        // Stop the animation when the spaceship is out of the viewport
        if (spaceshipPosition >= viewportWidth) {
          spaceship.style.transition = 'none';
        } else {
          spaceship.style.transition = 'transform 0.2s linear';
        }
      };

      moveSpaceship('.spaceship1', 1);
      moveSpaceship('.spaceship2', 0.8);
      moveSpaceship('.spaceship3', 1.2);
      
      let scrollRatio = 0;
      if (scrollPosition > 200) {
        const adjustedScrollPosition = scrollPosition - 200;
        const adjustedMaxScroll = maxScroll - 200;
        scrollRatio = (adjustedScrollPosition / adjustedMaxScroll) * 4;
        if (scrollRatio > 1) {
          scrollRatio = 1;
        }
      }
      
      // Increase the speed of the background color change by adjusting the scroll ratio
      const acceleratedScrollRatio = scrollRatio * 2.4; // Adjust this multiplier as needed
      const finalScrollRatio = Math.min(acceleratedScrollRatio, 1); // Ensure it doesn't exceed 1
      
      const circle = document.querySelector('.full-circle');
      const finalCircleColorHex = '#ffff4f';
      const finalBackgroundColorHex = '#a0f2fa';
      const finalStarColorHex = '#82e4ff';
      
      const finalCircleColorRGB = hexToRgb(finalCircleColorHex);
      const { r: rCircle, g: gCircle, b: bCircle } = finalCircleColorRGB;
      
      const finalBackgroundColorRGB = hexToRgb(finalBackgroundColorHex);
      const { r: rBackground, g: gBackground, b: bBackground } = finalBackgroundColorRGB;
      
      const finalStarColorRGB = hexToRgb(finalStarColorHex);
      const { r: rStar, g: gStar, b: bStar } = finalStarColorRGB;
      
      const currentCircleColor = `rgb(
        ${Math.round(finalScrollRatio * rCircle)},
        ${Math.round(finalScrollRatio * gCircle)},
        ${Math.round(finalScrollRatio * bCircle)}
      )`;
      
      const currentBackgroundColor = `rgb(
        ${Math.round(finalScrollRatio * rBackground)},
        ${Math.round(finalScrollRatio * gBackground)},
        ${Math.round(finalScrollRatio * bBackground)}
      )`;
      
      const currentStarColor = `rgb(
        ${Math.round((1 - finalScrollRatio) * 255 + finalScrollRatio * rStar)},
        ${Math.round((1 - finalScrollRatio) * 255 + finalScrollRatio * gStar)},
        ${Math.round((1 - finalScrollRatio) * 255 + finalScrollRatio * bStar)}
      )`;
      
      circle.style.backgroundColor = currentCircleColor;
      document.body.style.backgroundColor = currentBackgroundColor;
      
      const stars = document.querySelectorAll('.star');
      stars.forEach(star => {
        star.style.backgroundColor = currentStarColor;
      });
      
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = (bigint & 255);
    return { r, g, b };
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const createContactStars = () => {
      const contactSection = document.querySelector('.contact-section');
      const starsContainer = document.createElement('div');
      starsContainer.className = 'contact-stars';
      contactSection.insertBefore(starsContainer, contactSection.firstChild);

      const contactForm = contactSection.querySelector('.contact-form');
      const formRect = contactForm.getBoundingClientRect();

      for (let i = 0; i < 70; i++) {
        const star = document.createElement('div');
        star.className = 'contact-star';
        
        let left, top;
        do {
          left = Math.random() * 100;
          top = Math.random() * 100;
        } while (
          left > formRect.left && left < formRect.right &&
          top > formRect.top && top < formRect.bottom
        );

        star.style.left = `${left}%`;
        star.style.top = `${top}%`;
        starsContainer.appendChild(star);
      }
    };

    createContactStars();

    const handleScroll = () => {
      const contactSection = document.querySelector('.contact-section');
      if (contactSection) {
        const rect = contactSection.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        const sectionHeight = rect.height;
        
        const visibleRatio = visibleHeight / sectionHeight;

        if (visibleRatio > 0.7) {
          contactSection.classList.add('active');
          const stars = document.querySelectorAll('.contact-star');
          stars.forEach(star => {
            star.style.opacity = '1';
          });
        } else {
          contactSection.classList.remove('active');
          const stars = document.querySelectorAll('.contact-star');
          stars.forEach(star => {
            star.style.opacity = '0';
          });
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="app">
  <div className="container">
    {starPositions.map((position, index) => (
      <div className="star" style={position} key={index}></div>
    ))}
    {gStarPositions.map((position, index) => (
      <div className="star" style={position} key={index} ref={gStarRefs[index]}></div>
    ))}
    {aStarPositions.map((position, index) => (
      <div className="star" style={position} key={index} ref={aStarRefs[index]}></div>
    ))}
    {cStarPositions.map((position, index) => (
      <div className="star" style={position} key={index} ref={cStarRefs[index]}></div>
    ))}
    <div className="word games-word" onMouseEnter={handleMouseEnterGAMES} onMouseLeave={handleMouseLeaveGAMES} onClick={() => handleScrollToSection('games')} data-word="GAMES">GAMES</div>
    <div className="word about-word" onMouseEnter={handleMouseEnterABOUT} onMouseLeave={handleMouseLeaveABOUT} onClick={() => handleScrollToSection('about')} data-word="ABOUT US">ABOUT US</div>
    <div className="word contact-word" onMouseEnter={handleMouseEnterCONTACT} onMouseLeave={handleMouseLeaveCONTACT} onClick={() => handleScrollToSection('contact')} data-word="CONTACT">CONTACT</div>
  </div>
  <div className="sections">
    <section id="games" className="section games-section">
      <h2>GAMES</h2>
    </section>
    <section id="about" className="section about-section">
      <h2>ABOUT US</h2>
      <p>Lumen Studio is a pioneering game studio dedicated to creating community-driven mobile games that transcend mere entertainment. Our games are designed to address the pressing need for community in the hypercasual gaming space, offering players an opportunity to engage with each other in enriching and supportive environments. We believe that gaming should not only be fun but also a tool for building lasting relationships and strong communities. Join us on our journey to revolutionize the world of mobile gaming.</p>
    </section>
    <section id="contact" className="section contact-section">
      <h2>CONTACT</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="mission-control">Fill this out if you want to reach us!</div>
        <input type="text" name="name" placeholder="Name" required />
        <input type="email" name="email" placeholder="Email" required />
        <input type="text" name="subject" placeholder="Subject" required />
        <textarea name="message" placeholder="Message" required></textarea>
        <button type="submit">
          <div className="battery-fill"></div>
          <span className="button-text">Launch</span>
        </button>
        <div className="launch-countdown"></div>
      </form>
    </section>
  </div>

  <div className="black-square"></div>
  <div className="full-circle"></div>
  <img src="/Duck.gif" alt="Duck" className="duck-image" />
  <img src="/spaceship.png" alt="Spaceship 1" className="spaceship1" />
  <img src="/spaceship.png" alt="Spaceship 2" className="spaceship2" />
  <img src="/spaceship.png" alt="Spaceship 3" className="spaceship3" />
  <img src="/astronaut.png" alt="Astronaut" className="astronaut" />
  <img src="/linkedin.png" alt="Linkedin" className="linkedin" />
  <img src="/speech bubble.png" alt="Speech Bubble" className="speechbubble" />

  <img src="/Game-BG-website-ezgif.com-crop.gif" alt="Background 1" className="background1" id="background1"/>
  <div className="jimmy-template">
    <img src="/jimmy-template.png" alt="JimmyTemplate" className="jimmy-template-image" />
    <div className="jimmy-info">
      <h3>Jimmy | Co-Founder</h3>
      <p>Major: <br></br>CS + Operations Research @ UC Berkeley</p>
      <p>Favorite Game: <br></br>Minecraft</p>
      <p>Motivation: <br></br>Who doesn't want to make video games for a living?</p>
    </div>
  </div>
  <div className="etai-template">
    <img src="/etai-template.png" alt="EtaiTemplate" className="etai-template-image" />
    <div className="etai-info">
      <h3>Etai | Co-Founder</h3>
      <p>Major: <br></br>CS @ UC Berkeley</p>
      <p>Favorite Game: <br></br>MapleStory</p>
      <p>Motivation: <br></br>To make an impact on people's lives</p>
    </div>
  </div>
</div>

  );
}

export default App;

